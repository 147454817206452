import { action, computed, observable } from 'mobx'

export class TemplateViewStore {
    @observable header: boolean = false
    @observable side: boolean = false

    @action
    showHeader(show: boolean) {
        this.header = show
    }

    @action
    showSide(show: boolean) {
        this.side = show
    }

    @computed
    get isShowHeader() {
        return this.header
    }

    @computed
    get isShowSide() {
        return this.side
    }
}

export const TemplateView = new TemplateViewStore()